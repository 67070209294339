/*
Mailing Views
*/
//Reportes Mailing
const ReportesMail = () => import('@/views/mail_views/reportes/ReportesMail.vue')
//Mailing Estadisticas
const FiltroEstadisticasMail = () => import('@/views/mail_views/estadisticas/FiltroEstadisticasMail.vue')
// Busqueda Mail 
const BusquedaMail = () => import('@/views/mail_views/reportes/BusquedaMail.vue')
//Grupos Sms
const GruposMail = () => import('@/views/mail_views/grupos_mail/Grupos.vue')
const IntegrantesGrupos = () => import('@/views/mail_views/grupos_mail/AgregarMails.vue')
const IndexBoletin = () => import('@/view_mails/boletines/index/IndexBoletin.vue')
const CrearBoletin = () => import('@/view_mails/boletines/create/CreateBoletin.vue')
const UpdateBoletin = () => import('@/view_mails/boletines/update/UpdateBoletin.vue')
const CarguesProgramados = () => import('@/view_mails/boletines/cargues/CarguesProgramados.vue')
const ListaNegra = () => import('@/view_mails/lista_negra/ListaNegra.vue')
const Usuarios = () => import('@/view_mails/usuarios/Usuarios.vue')
const CrearUsuario = () => import('@/view_mails/usuarios/CrearUsuario.vue')
const EditarUsuario = () => import('@/view_mails/usuarios/EditarUsuario.vue')


const routes_mail = [
    {
        path: 'mailing/reportes',
        name: 'Mailing Reportes',
        component: ReportesMail,
        meta:{
          requiresAuth: true,
          label: 'Mailing Reportes',
          permissions:'reportes.index'
        }
    },
    {
      path: 'mailing/busqueda/email',
      name: 'Mailing Busqueda Mail',
      component: BusquedaMail,
      meta:{
        requiresAuth: true,
        label: 'Mailing Busqueda Mail',
        permissions:'reportes.index'
      }
    },
    {
      path: 'mailing/estadisticas',
      meta: {
        label: 'Mailing Estadisticas',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Mailing Estadisticas',
          meta: {
            //permissions:'subcuenta.index',
          },
          component: FiltroEstadisticasMail
        },
      ]
    },
    {
      path: 'mailing/grupos',
      meta: {
        label: 'GruposMail',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'GruposMail',
          meta: {
            permissions:'mail_grupos.index',
          },
          component: GruposMail
        },
        {
          path: 'integrantes/:id',
          meta: {
            label: 'Integrantes Grupos',
            permissions:'mail_grupos.index'
          },
          name: 'Integrantes Grupos',
          component: IntegrantesGrupos
        },
      ]
    },
    {
      path: 'mailing/boletines',
      meta: {
        label: 'Boletines',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Boletines',
          meta: {
            permissions:'mail_boletines.index',
          },
          component: IndexBoletin
        },
        {
          path: 'create',
          meta: {
            label: 'Crear Boletin',
            permissions:'mail_boletines.index'
          },
          name: 'Crear Boletin',
          component: CrearBoletin
        },
        {
          path: 'update/:id',
          meta: {
            label: 'Actualizar Boletin',
            permissions:'mail_boletines.index'
          },
          name: 'Actualizar Boletin',
          component: UpdateBoletin
        },
      ]
    },
    {
      path: 'mailing/cargues',
      name: 'Cargues Pendientes',
      component: CarguesProgramados,
      meta:{
        requiresAuth: true,
        label: 'CarguesProgramados',
        permissions:'mail_boletines.index'
      }
    },
    {
      path: 'mailing/bloqueados',
      name: 'Lista Negra',
      component: ListaNegra,
      meta:{
        requiresAuth: true,
        label: 'ListaNegra',
        permissions:'mail_lista_negra.index'
      }
    },
    {
      path: 'mailing/usuarios',
      meta: {
        label: 'Usuarios',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Usuarios',
          meta: {
            permissions:'mail_usuarios.index',
          },
          component: Usuarios
        },
        {
          path: 'crear',
          meta: {
            label: 'Crear Usuario',
            permissions:'mail_usuarios.index'
          },
          name: 'CrearUsuario',
          props: { columnas:Object },
          component: CrearUsuario
        },
        {
          path: 'editar/:id',
          meta: {
            label: 'Editar Usuario',
            permissions:'mail_usuarios.index'
          },
          name: 'EditarUsuario',
          component: EditarUsuario
        },
      ]
    },
]
export default routes_mail
   