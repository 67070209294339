import axios from 'axios'
import APP_CONFIG from '../../.env.js';
import { apiClient } from '../utils/apiClient';

//axios.defaults.baseURL = APP_CONFIG.URL;
axios.defaults.withCredentials = true;
export default {
    namespaced:true,
    state:{
        user:null,
        auth:false,
        permissions:[],
        logo_web:null,
        loading_http:'hidden',
        noticias_modal:'hidden',
        psevalida: null,
    },
    mutations:{
        SET_USER(state,user){
            state.user = user == null ? null : user.user;
            state.auth = Boolean(user);
            let token = '';
            try {
              token = user.token;
              if (token != undefined) {
                localStorage.setItem('sms_token',token);
              }
              if (user['X-Mailing-Token'] != undefined) {
                localStorage.setItem('X-Mailing-Token',user['X-Mailing-Token']);
              }
            } catch (error) {
                console.log(error);
                
              if (!Boolean(user)) {
                localStorage.setItem('sms_token','');
              }
            }
            let accessToken = localStorage.getItem('sms_token');
            localStorage.setItem('loggedIn',Boolean(user));
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            /*let tokenMail = localStorage.getItem('X-Mailing-Token');
            axios.defaults.headers.common['X-Mailing-Token'] = tokenMail;*/
            if (state.auth) {
                this.dispatch('auth/getPermissions',state.user.id);
                this.dispatch('auth/nuevoLogo',state.user.sms.logo);
                this.dispatch('auth/getValidaPse');
            }
        },
        SET_LOGO(state, logo){
            state.logo_web = logo;
        },
        SET_LOADING_HTTP(state, estado){
            state.loading_http = estado;
        },
        SET_NOTICIAS_MODAL(state, estado){
            state.noticias_modal = estado;
        },
        clearUserData () {
            localStorage.removeItem('sms_token')
            localStorage.removeItem('loggedIn')
            location.reload()
            window.location.href = '/';
        },
        SET_DATA_PERMISSIONS(state,permission){
            state.permissions = permission;
        },
        SET_DATA_PSE_VALIDA(state,pse_result){
            state.psevalida = pse_result;
        }
    },
    actions:{
        async login ({ commit }, credentials) {
            await axios.get('/sanctum/csrf-cookie');
            return apiClient
              .post('/sms/login', credentials)
              .then(({ data }) => {
                commit('SET_USER', data)
              }).catch(error => {return error;})
        },
        logout ({ commit }) {
        commit('SET_USER',null);
        commit('clearUserData')
        },
        getUser({commit}){
        const token = localStorage.getItem('sms_token');
        apiClient.get('/sms/user',{
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            }
        }
        ).then(response => {
            commit('SET_USER',response.data);
            /*if (response.data.user.id) {
                this.dispatch('getPermissions',response.data.user.id);
            }*/           
        }).catch((error)=>{
            commit('SET_USER',null);
        });
        },
        getPermissions({ commit },user_id){
            apiClient.get('/sms/permissions/'+user_id).then(response => {
                commit('SET_DATA_PERMISSIONS',response.data);
            }).catch(()=>{
                commit('SET_DATA_PERMISSIONS',null);
            });
        },
        getValidaPse({ commit }){
            apiClient.get('/sms/pse').then(response => {
                commit('SET_DATA_PSE_VALIDA',response.data);
            }).catch(()=>{
                commit('SET_DATA_PSE_VALIDA',null);
            });
        },
        nuevoLogo({ commit },logo){
            //console.log(logo);
            //logo = logo.trim();
            let url = ''
            if (logo == '' || logo == null) {
                url = APP_CONFIG.URL+`/dist/img/avatars/logo_it_cloud.png`;
            }else{
                url = APP_CONFIG.URL+`/storage/logo/${logo}`;
            }
            commit('SET_LOGO',url);
        },
        loading_http({ commit },estado){
            commit('SET_LOADING_HTTP',estado);
        },
        noticias_modal({ commit },estado){
            commit('SET_NOTICIAS_MODAL',estado);
        },
    },
    getters:{
        getpermissionsNav: state => {
            return state.permissions
        }
    }
}