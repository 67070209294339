<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import '@/assets/scss/style';
  .tox-notifications-container{
        display: none !important;
  }
  .tox .tox-notification--warn, .tox .tox-notification--warning{
      display: none !important;
  }
  .tox tox-silver-sink tox-tinymce-aux{
      display: none !important;
  }
  .tox:not([dir=rtl]) .tox-statusbar__branding{
      display: none !important;
  }
  .ck-powered-by {
        display: none !important;
  }
  .tox-notification .tox-notification--in .tox-notification--warning{
      display: none !important;
  }
  .tox-notifications-container{
      display: none !important;
  }
</style>
