import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import APP_CONFIG from '../.env.js';
import crearcuentasms from './modules/crearcuentasms';
import auth from './modules/auth';

axios.defaults.baseURL = APP_CONFIG.URL;
axios.defaults.withCredentials = true;
axios.defaults.encoding = 'utf-8';
axios.defaults.responseEncoding = 'utf-8';

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
}
const actions = {

}

const getters = {
}
const modules = {
  auth,
  crearcuentasms
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
})
