export default {
    namespaced:true,
    state:{
        formularioRegistrar:{
            nombre:'',
            empresa:'',
            email:'',
            pais:'',
            celular:'',
            servicios:'',
            habeas:false
        },
        mensajeRegistrado:false,
    },
    mutations:{
        SET_DATA_FORMULARIO_REGISTRAR(state,form){
            state.formularioRegistrar = form;
        },
        SET_DATA_MENSAJE_REGISTRAR(state,tipo){
            state.mensajeRegistrado = tipo;
        }
    },
    actions:{
        guardarDataFormularioRegistrar({ commit },form){
            commit('SET_DATA_FORMULARIO_REGISTRAR',form);
        },
        guardarMensajeRegistrar({ commit },tipo){
            commit('SET_DATA_MENSAJE_REGISTRAR',tipo);
        }
    },
    getters:{

    }
}