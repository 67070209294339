import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import APP_CONFIG from '../.env.js';
import Donut from 'vue-css-donut-chart';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-select/dist/vue-select.css";
import EasySlider from 'vue-easy-slider'
import { apiClient } from './utils/apiClient';


Vue.prototype.$apiAdress = APP_CONFIG.URL;
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$http = apiClient;
Vue.prototype.$httpUrlMail = APP_CONFIG.URL_MAILING;

store.dispatch('auth/getUser').catch(() => {});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  render: h => h(App)
})

Vue.prototype.$can = (permiso) =>{
  const permissions = store.state.auth.permissions;
  let $return = false;
  if (permissions.length > 0) {
     let contador = 0;
     for (const iterator of permissions) {
        //if (to.matched.some(route => route.meta.permissions == iterator.name)) {
          //console.log(iterator.name);
          //console.log(permiso == iterator.name);
        if (permiso == iterator.name) {
           contador++;
        }
      }
      if (contador > 0) {
        $return = true;
      }else{
        $return = false;
      }
      
  }else{
    $return = false;
  }
  return $return;
}

Vue.prototype.$formatterNumeroTipoCreditos = (value,tipo = 0) => {
  const formatterDolar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    })
    if (tipo == 1) {
       return (formatterDolar.format(value)).replace('$','');
    }else{
      let numero = (formatterDolar.format(value)).replace('$','');
      numero = numero.replace('.00','');
      numero = numero.replaceAll(',','.');
      return numero;
    }
}

Vue.use(Donut)
Vue.use(VueSweetalert2);
Vue.use(EasySlider)