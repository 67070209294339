import Vue from 'vue'
import Router from 'vue-router'
import store from '../store';
import APP_CONFIG from '../../.env.js'
import routes_mail from './routes_mail';
import createWebHistory from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer.vue')

// Views
const Dashboard = () => import('@/views/Dashboard.vue')

/*const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')
*/
// Views - Pages

/*const Page500 = () => import('@/views/pages/Page500')
const Register = () => import('@/views/pages/Register')*/
const Login = () => import('@/views/pages/Login.vue')
const Page404 = () => import('@/views/pages/Page404.vue')

// Users
/*const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')*/

//Roles
const Roles = () => import('@/views/roles_permisos/Roles.vue')
const RolesEditar = () => import('@/views/roles_permisos/EditarRoles.vue')
const RolesCrear = () => import('@/views/roles_permisos/CrearRoles.vue')

//Registar Cuenta Sms 
const CrearCuentaSms = () => import('@/views/crear_cuenta_sms/CrearCuentaSms.vue')

//Usuarios
const Usuarios = () => import('@/views/usuarios/Usuarios.vue')
const EditarUsuario = () => import('@/views/usuarios/EditarUsuario.vue')
const CrearUsuario = () => import('@/views/usuarios/CrearUsuario.vue')
const PseImpuestos = () => import('@/views/usuarios/PseImpuestos.vue')
const RecargasUsuario = () => import('@/views/usuarios/Recargas.vue')
const PrecioClienteUsuario = () => import('@/views/usuarios/PrecioCliente.vue')
const LogEnvio = () => import('@/views/usuarios/LogEnvio.vue')
const LogFraudeLink = () => import('@/views/link_fraude/LogFraudeLink.vue')
//precio Sms
const PrecioSms = () => import('@/views/precio_sms/PrecioSms.vue')
//Subcuentas Usuarios
const SubCuenta = () => import('@/views/subcuentas/SubCuenta.vue')
const RecargaSubCuenta = () => import('@/views/subcuentas/RecargasSubCuenta.vue')
//Mensajes de Texto
const MensajesTexto = () => import('@/views/mensajes_texto/MensajesTexto.vue')
//Grupos Sms
const GruposSms = () => import('@/views/grupos_sms/Grupos.vue')
const IntegrantesGrupos = () => import('@/views/grupos_sms/AgregarNumeros.vue')
//Historial Recarga
const HistorialRecarga = () => import('@/views/historial_recarga/HistorialRecarga.vue')
//Reportes
const Reportes = () => import('@/views/reportes/Reportes.vue')
//Cargues Pendientes
const CarguesProgramados = () => import('@/views/cargues/CarguesProgramados.vue')
//recarga pse
const RecargaPse = () => import('@/views/pse/RecargaPse.vue')

//Lista Negra
const ListaNegra = () => import('@/views/lista_negra/ListaNegra.vue')
//Estadisticas
const FiltroEstadisticas = () => import('@/views/estadisticas/FiltroEstadistica.vue')
//const Estadisticas = () => import('@/views/estadisticas/Estadisticas')
//Contenido Ayuda
const ContenidoAyuda = () => import('@/views/contenido_ayuda/ContenidoAyuda.vue')
//Designer sms
const Designer = () => import('@/views/contenido_ayuda/Designer.vue')
//noticias
const Noticias = () => import('@/views/noticias/Noticias.vue')
const CrearNoticia = () => import('@/views/noticias/CrearNoticia.vue')
const EditarNoticia = () => import('@/views/noticias/EditarNoticia.vue')
const VerNoticia = () => import('@/views/noticias/VerNoticia.vue')
const VerNoticiaUsuarios = () => import('@/views/noticias/VerNoticiaUsuarios.vue')

//Bloqueo Numeros Sms publico
const BloqueoNumeros = () => import('@/views/pages/BloqueoNumeros.vue')
//const Prueba = () => import('@/views/mensajes_texto/ChartMapa')
//Restablecer Clave
const OlvideClave = () => import('@/views/pages/OlvideClave.vue')
//palabras Spam
const PalabrasSpam = () => import('@/views/palabras_spam/PalabrasSpam.vue')
//Arbol Usuarios 
const ArbolUsuarios = () => import('@/views/usuarios/ArbolUsuarios.vue')

Vue.use(Router)


function configRoutes () {
  const baseRoutesSms = [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: 'roles',
      meta: {
        label: 'Roles',
        requiresAuth: true,
        //permissions:'rol.index'
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Roles',
          meta: {
            permissions:'rol.index',
          },
          component: Roles
        },
        {
          path: 'editar/:id',
          meta: {
            label: 'Editar Roles',
            permissions:'rol.edit'
          },
          name: 'EditarRoles',
          component: RolesEditar
        },
        {
          path: 'crear',
          meta: {
            label: 'Crear Roles',
            permissions:'rol.create'
          },
          name: 'CrearRoles',
          component: RolesCrear
        }
      ]
    },
    {
      path: 'usuarios',
      meta: {
        label: 'Usuarios',
        requiresAuth: true,
        //permissions:'rol.index'
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Usuarios',
          meta: {
            permissions:'usuario.index',
          },
          component: Usuarios
        },
        {
          path: 'editar/:id',
          meta: {
            label: 'Editar Usuarios',
            permissions:'usuario.edit'
          },
          name: 'EditarUsuarios',
          component: EditarUsuario
        },
        {
          path: 'crear',
          meta: {
            label: 'Crear Usuario',
            permissions:'usuario.create'
          },
          name: 'CrearUsuario',
          component: CrearUsuario
        },
        {
        path: 'logenvio/:id',
          meta: {
            label: 'Log de Envio',
            permissions:'usuario.logenvio'
          },
          name: 'LogEnvio',
          component: LogEnvio
        },
        {
          path: 'impuestos/:id',
          meta: {
            label: 'PSE e Impuestos Usuario',
            permissions:'usuario.impuestos'
          },
          name: 'PSEImpuestosUsuario',
          component: PseImpuestos
        },
        {
          path: 'recargas/:id',
          meta: {
            label: 'Recargas Usuario',
            permissions:'usuario.recargas'
          },
          name: 'RecargasUsuarios',
          component: RecargasUsuario
        },
        {
          path: 'precio/cliente/:id',
          meta: {
            label: 'Precio Cliente',
            permissions:'usuario.precio_cliente'
          },
          name: 'PrecioCliente',
          component: PrecioClienteUsuario
        },
      ]
    },
    {
      path: 'usuarios/arbol',
      name: 'Arbol Usuarios',
      component: ArbolUsuarios,
      meta:{
        requiresAuth: true,
        label: 'Arbol Usuarios',
        permissions:'arbol.usuarios'
      }
    },
    {
      path: 'precio/sms',
      name: 'Precio Sms',
      component: PrecioSms,
      meta:{
        requiresAuth: true,
        label: 'Precio Sms',
        permissions:'precio.precio_sms'
      }
    },
    {
      path: 'logfraude',
      meta: {
        label: 'Log Link Fraude',
        requiresAuth: true,
        permissions:'usuario.logfraude'
      },
      name: 'LogFraudeLink',
      component: LogFraudeLink
    },
    {
      path: 'palabras',
      meta: {
        label: 'Palabras Spam',
        requiresAuth: true,
        permissions:'usuario.palabra_spam'
      },
      name: 'PalabrasSpam',
      component: PalabrasSpam
    },
    {
      path: 'recarga/pse',
      meta: {
        label: 'Recarga Pse',
        requiresAuth: true,
      },
      name: 'RecargaPse',
      component: RecargaPse
    },
    {
      path: 'historial/recarga',
      name: 'Historial Recarga',
      component: HistorialRecarga,
      meta:{
        requiresAuth: true,
        label: 'Historial Recarga',
        permissions:'historial_recarga.index'
      }
    },
    {
      path: 'contenidoayuda',
      name: 'Contenido Ayuda',
      component: ContenidoAyuda,
      meta:{
        requiresAuth: true,
        label: 'Contenido Ayuda',
      }
    },
    {
      path: 'designer',
      name: 'Designer',
      component: Designer,
      meta:{
        requiresAuth: true,
        label: 'Designer',
      }
    },
    {
      path: 'reportes',
      name: 'Reportes',
      component: Reportes,
      meta:{
        requiresAuth: true,
        label: 'Reportes',
        permissions:'reportes.index'
      }
    },
    {
      path: 'cargues',
      name: 'Cargues Pendientes',
      component: CarguesProgramados,
      meta:{
        requiresAuth: true,
        label: 'CarguesProgramados',
        permissions:'cargues.index'
      }
    },
    {
      path: 'numeros/bloqueados',
      name: 'Numeros Bloqueados',
      component: ListaNegra,
      meta:{
        requiresAuth: true,
        label: 'Numeros Bloqueados',
        permissions:'lista_negra.index'
      }
    },
    {
      path: 'subcuentas/usuarios',
      meta: {
        label: 'SubCuenta',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'SubCuenta',
          meta: {
            permissions:'subcuenta.index',
          },
          component: SubCuenta
        },
        {
          path: 'recargas/:id',
          meta: {
            label: 'Recargas SubCuenta',
            permissions:'subcuenta.recargas'
          },
          name: 'RecargasSubCuenta',
          component: RecargaSubCuenta
        },
      ]
    },
    {
      path: 'mensajes/texto',
      meta: {
        label: 'MensajeTexto',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'MensajeTexto',
          meta: {
            permissions:'mensaje_texto.index',
          },
          component: MensajesTexto
        },
      ]
    },
    {
      path: 'grupos',
      meta: {
        label: 'GruposSms',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'GruposSms',
          meta: {
            permissions:'grupos_sms.index',
          },
          component: GruposSms
        },
        {
          path: 'integrantes/:id',
          meta: {
            label: 'Integrantes Grupos',
            permissions:'grupos_sms.create'
          },
          name: 'Integrantes Grupos',
          component: IntegrantesGrupos
        },
      ]
    },
    {
      path: 'estadisticas',
      meta: {
        label: 'Estadisticas',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Estadisticas',
          meta: {
            //permissions:'subcuenta.index',
          },
          component: FiltroEstadisticas
        },
        /*{
          path: '/chart',
          meta: {
            label: 'Estadisticas',
            //permissions:'subcuenta.recargas'
          },
          name: 'Estadistica',
          component: Estadisticas
        },*/
      ]
    },
    {
      path: 'noticias',
      meta: {
        label: 'Noticias',
        requiresAuth: true,
      },
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'Noticias',
          meta: {
            //permissions:'subcuenta.index',
          },
          component: Noticias
        },
        {
          path: '/crear/noticia',
          meta: {
            label: 'Crear Noticia',
            //permissions:'subcuenta.recargas'
          },
          name: 'CrearNoticia',
          component: CrearNoticia
        },
        {
          path: '/noticia/editar/:id',
          meta: {
            label: 'Editar Noticia',
            //permissions:'subcuenta.recargas'
          },
          name: 'EditarNoticia',
          component: EditarNoticia
        },
      ]
    },
];
  return [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/envio/login',
      name: 'envio_login',
      component: Login
    },
    {
      path: '/',
      redirect: '/login',
      name: '',
      component: TheContainer,
      meta:{
        requiresAuth: true
      },
      children: baseRoutesSms.concat(routes_mail)
},
    {
      path: '/registrar',
      name: 'registrar',
      component: CrearCuentaSms
    },
    {
      path: '/bloquear/numero',
      name: 'BloqueoNumeros',
      component: BloqueoNumeros
    },
    {
      path: '/restablecer/clave',
      name: 'OlvideClave',
      component: OlvideClave
    },
    /*{
      path: '/prueba',
      name: 'Prueba',
      component: Prueba
    },*/
    {
      path: '*',
      name: 'Page404',
      component: Page404
    },
  ]
}

 const routes = new Router({
  base: APP_CONFIG.SUBDIRECTORY,
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

routes.beforeEach((to,from,next)=>{
    let logged= localStorage.getItem('loggedIn') == 'true' ? true : false;
    const existToken = localStorage.getItem('sms_token') != null;
    const loggedIn = logged && existToken;
    if (to.matched.some(route => route.meta.requiresAuth)) {
      if (to.matched.some(route => route.meta.permissions)) {
        if (isPermissions(to)) {
           next('*');
        }
      }
      if (!loggedIn) {
        let tipoLogin = localStorage.getItem('tipo_login');
         if(tipoLogin == 'envio_login') {
            next('/envio/login');
         }else{
            next('/login');
         }
      } else {
        next();
      }
    }else if(to.matched.some(route => route.name == 'login' || route.name == 'envio_login') && loggedIn){
      next();
    }else{
      next();
    }
    store.dispatch('auth/getUser').catch(() => {});
})

const isPermissions = (to) =>{
  const permissions = store.state.auth.permissions;
  if (permissions.length > 0) {
     let contador = 0;
     for (const iterator of permissions) {
        //if (to.matched.some(route => route.meta.permissions == iterator.name)) {
        if (to.meta.permissions == iterator.name) {
           contador++;
        }
      }
      if (contador > 0) {
        return false;
      }else{
        return true;
      }
      
  }else{
    return false;
  }
}

export default routes